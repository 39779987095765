<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <h2 class="brand-text text-primary ml-1 gram-logo">
          Gram
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="h2"
          >
            Добро пожаловать в Gram! 👋
          </b-card-title>
          <b-card-text class="mb-2">
            Пожалуйста, войдите в свой аккаунт и начните работу
          </b-card-text>

          <validation-observer
            ref="loginForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="login"
            >
              <!-- email -->
              <b-form-group
                label="Логин"
                label-for="login-phone"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required"
                >
                  <b-form-input
                    id="login-phone"
                    v-model="phone"
                    :state="errors.length > 0 ? false:null"
                    name="login-phone"
                    placeholder="Логин"
                  />
                  <small class="text-danger" v-if="errors[0]">{{ 'Логин обязателен для заполнения'}}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Пароль</label>
                  <router-link :to="{name:'auth-forgot-password'}">
                    <small>Забыли пароль?</small>
                  </router-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="Пароль"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger" v-if="errors[0]">{{ 'Пароль обязателен для заполнения' }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  Запомнить
                </b-form-checkbox>
              </b-form-group>
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="invalid || submitButtonDisabled"
              >
                Вход
              </b-button>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton, BAlert, VBTooltip,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      email,
      password: '',
      phone: '', 
      required,
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      status: false,
      submitButtonDisabled: false,
    }
  },
  mounted(){
    const savedPhone = localStorage.getItem('phone');
    const savedPassword = localStorage.getItem('password');

    if (savedPhone && savedPassword) {
      this.phone = savedPhone;
      this.password = savedPassword;
      this.status = true
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    login() {
      this.submitButtonDisabled = true
      fetch(`${this.$baseURL}auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ login: this.phone, password: this.password })
      }).then(res => {
        if (res.status == 401) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
                title: 'Неправильный логин или пароль!',
                icon: 'XIcon',
                variant: 'danger',
            },
          })
        }else {
          res.json().then(json=>{
            const userData  = json
            userData.ability = [
              {
                action: 'manage',
                subject: 'all',
              },
            ]
            userData.access = userData.access_routes
            useJwt.setToken(json.access_token)
            localStorage.setItem('userData', JSON.stringify(userData))
            // this.$ability.update(userData.ability)
            this.$router.go(0);
          })        
        }

      }).finally(()=>{
        this.submitButtonDisabled = false
        this.status = false;
      })
      if (this.status === true) {
        localStorage.setItem('phone', this.phone);
        localStorage.setItem('password', this.password);  
      }else {
          localStorage.removeItem('phone');
          localStorage.removeItem('password');
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
.gram-logo{
  color: #042AEC !important;
}
</style>
